"use client";

import { useWindowEvent } from "@mantine/hooks";
import { useRouter } from "next/navigation";
import { FC } from "react";

const useRefreshOnFocus = () => {
  const router = useRouter();

  useWindowEvent("focus", () => {
    router.refresh();
  });
};

export const RefreshOnFocus: FC = () => {
  useRefreshOnFocus();

  return null;
};
