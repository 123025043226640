"use client";

import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect } from "react";

const usePosthogUserSession = (version: string) => {
  const session = useSession();
  const posthog = usePostHog();

  useEffect(() => {
    if (session.data?.user) {
      posthog.identify(session.data.user.email, { email: session.data.user.email, version });
    } else {
      posthog.reset();
    }
  }, [posthog, session.data?.user]);
};

const useSentryUserSession = (version: string) => {
  const session = useSession();

  useEffect(() => {
    Sentry.setTag("version", version);

    if (session.data?.user) {
      Sentry.setUser({ email: session.data.user.email });
    } else {
      Sentry.setUser(null);
    }
  }, [session.data?.user]);
};

interface Props {
  version: string;
}

export const CaptureUserSession: FC<Props> = ({ version }) => {
  usePosthogUserSession(version);
  useSentryUserSession(version);

  return null;
};
